import React from "react";
import Container from "../../components/chat-channel/Container";
import Layout from "../../components/chat-channel/Layout";
import RegisterSection from "../../components/chat-channel/ChannelHeader";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import CenterContentHome from "../../components/common/CenterContentHeader";
import { RequestForm } from "../../components/form";

const topImage = require("../../assets/images/10-pages/con-it-support/con_it_support_header.png");
const card_one = require("../../assets/images/10-pages/con-it-support/con_it_support_sec_1_1.png");
const card_two = require("../../assets/images/10-pages/con-it-support/con_it_support_sec_1_2.png");
const card_three = require("../../assets/images/10-pages/con-it-support/con_it_support_sec_1_3.png");
const card_four = require("../../assets/images/10-pages/con-it-support/con_it_support_sec_2_1.png");
const card_five = require("../../assets/images/10-pages/con-it-support/con_it_support_sec_2_2.png");
const card_six = require("../../assets/images/10-pages/con-it-support/con_it_support_sec_2_3.png");
const card_7 = require("../../assets/images/10-pages/con-it-support/con_it_support_sec_3_1.png");
const card_8 = require("../../assets/images/10-pages/con-it-support/con_it_support_sec_3_2.png");
const card_9 = require("../../assets/images/10-pages/con-it-support/con_it_support_sec_3_3.png");
const card_10 = require("../../assets/images/10-pages/con-it-support/con_it_support_sec_4_1.png");
const card_11 = require("../../assets/images/10-pages/con-it-support/con_it_support_sec_4_2.png");
const card_12 = require("../../assets/images/10-pages/con-it-support/con_it_support_sec_4_3.png");

export const dataProtection = [
  {
    textHeader: "User Experience",
    bg:"bg_white",
    cards: [
      {
        image: card_one,
        header: "Transform end-user experience",
        content:
          "Modernize end-user experience — from reactive (calls & emails to IT Helpdesk), to proactive – an intelligent chatbot waiting to resolve inquiries & requests",

        uuid: "a",
        
      },
      {
        image: card_two,
        header: "Next generation conversational self-service",
        content:
          "Build your conversational IT support chatbot with zero-coding and enable a smart AI based self-service for employees. Delivers autonomous resolution.",
        
      },
      {
        image: card_three,
        header: "Use Slack and Teams to deliver IT Support",
        content:
          "Deploy chatbot on Slack or Teams in few clicks. And delight your employees with an always-on, follow-me, interactive chatbot, from the comfort of their favorite apps like Slack or Teams.",
      },
    ],
  },

  {
    textHeader: "IT workflows",
    bg:"bg_grey",
    cards: [
      {
        image: card_four,
        header: "No-code IT workflow designer",
        content:
          "Automating stuff shouldn’t be a pain. That’s why we’ve done all the heavy lifting for you. Use our no-code workflow automation designer at no additional cost to create IT workflows for chatbot.",
        uuid: "a",
      },
      {
        image: card_five,
        header: "Pre-built workflows IT workflows",
        content:
          "Workativ assistant comes with 400+ pre-built IT workflows that you can use from our marketplace and go live instantly.",
      },
      {
        image: card_six,
        header: "Chatbot + IT workflow automations",
        content:
          "Conversational ai is not enough. That’s why our platform comes with powerful workflow automation built in with conversational ai to deliver resolutions in seconds.",
      },
    ],
  },
  {
    textHeader: "Agent experience",
    bg:"bg_white",
    cards: [
      {
        image: card_7,
        header: "Reduce agent burnout and boost productivity",
        content:
          "Repetitive work can dampen agent skills and morale, leading to attrition. Workativ gives back more time to agents to focus on high-value tasks.",
        uuid: "a",
      },
      {
        image: card_8,
        header: "Scale faster with an IT support chatbot",
        content:
          "Our chatbot can address end-user issues concurrently and help scale your support delivery in minutes. Add digital worker to your support delivery team.",
      },
      {
        image: card_9,
        header: "Increase IT helpdesk performance",
        content:
          "Our chatbot qualifies your incoming inquiry and only passes to a live agent if required. The tickets are classified, categorized, and assigned to the right team saving a ton of manual work.",
      },
    ],
  },
  {
    textHeader: "Scaling, Performance, Productivity",
    bg:"bg_grey",
    cards: [
      {
        image: card_10,
        header: "Anytime, anywhere 24/7",
        content:
          "With an intelligent chatbot, employees can get instant support 24/7 regardless of where they are. Scale your support team even when they are busy or remote.",
        uuid: "a",
      },
      {
        image: card_11,
        header: "Boost your MTTR and CSAT",
        content:
          "Real experience is now, not later, then why should your employees wait on support. Our chatbot can deliver an exceptional experience (instant resolutions).",
      },

      {
        image: card_12,
        header: "Lightning-fast first contact resolution",
        content:
          "With our chatbot, you can eliminate the need to go back and forth on most IT support tickets with our automated resolution – our unique differentiator.",
      },
    ],
  },
];
console.log("dataProtection", dataProtection);
export default function FeatureHomePage() {
  return (
    <>
      <TitleAndMetaTags
        title="Conversational AI for IT Support | Automate IT Issues with Conversational AI"
        description="Use conversational ai to automate frequent IT issues and requests instantly. Provide employee IT self-service using conversational ai chatbots with automated IT workflows and save costs."
        keywords={["IT Helpdesk Chatbot", "HR Chatbot", "Service Desk Chatbot"]}
        ogImage={topImage}
        ogTitle="Conversational AI for IT Support | Automate IT Issues with Conversational AI"
        ogDescription="Use conversational ai to automate frequent IT issues and requests instantly. Provide employee IT self-service using conversational ai chatbots with automated IT workflows and save costs."
      />
      <Container>
        <Layout backgroundColor={"bg_header_it_support"} logoFor="ASSISTANT">
          <RegisterSection
            rightImage={topImage}
            backgroundColor={"bg_header_it_support"}
            altImage={"Conversational IT Support"}
          >
            <RegisterSection.FormWithContent>
              <RegisterSection.MainHeader>
                Conversational IT Support – faster, better, and easier
              </RegisterSection.MainHeader>
              <RegisterSection.SubHeader>
                Transform your IT support using conversational ai to resolve
                frequent employee IT issues and service requests instantly.
              </RegisterSection.SubHeader>
            </RegisterSection.FormWithContent>
          </RegisterSection>{" "}
          <CenterContentHome>
            <CenterContentHome.Header>
              What makes our Conversational AI for IT Support solution different
            </CenterContentHome.Header>
          </CenterContentHome>
          <div className="security_wrapper _wrapper_support">
            {dataProtection.map((data, index) => {
              // console.log("rrr", dataProtection);
              if (index % 2 == 0) {
                return (
                  <div className="resources_card">
                    <section className="whitepaper_cards">
                      <div className="container">
                        <div className="row">
                          <h4>{data.textHeader}</h4>
                          <ul className="cards">
                            {data.cards.map((cards) => (
                              <li className="cards__item">
                                <div className="card">
                                  <div className="card__image">
                                    <img
                                      src={cards.image}
                                      alt={cards.header}
                                    ></img>
                                  </div>
                                  <div className="card__content">
                                    <div className="card__title">
                                      {cards.header}
                                    </div>
                                    <p className="card__text">
                                      {cards.content}
                                    </p>
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </section>
                  </div>
                );
              }
              return (
                <div className="resources_card bg_ccc">
                  <section className="whitepaper_cards">
                    <div className="container">
                      <div className="row">
                        <h4>{data.textHeader}</h4>
                        <ul className="cards">
                          {data.cards.map((cards) => (
                            <li className="cards__item">
                              <div className="card">
                                <div className="card__image">
                                  <img
                                    src={cards.image}
                                    alt={cards.header}
                                  ></img>
                                </div>
                                <div className="card__content">
                                  <div className="card__title">
                                    {cards.header}
                                  </div>
                                  <p className="card__text">{cards.content}</p>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </section>
                </div>
              );
            })}
          </div>
          <Example />
          <div className="mb_footer">
            <RequestForm isFooterForm={true} />
          </div>
        </Layout>
      </Container>
    </>
  );
}
function Example() {
  return (
    <React.Fragment>
        <div className="mob_accordian">
          <div className="container">
      {dataProtection.map((data) => (
        <Accordion className={data.bg}  preExpanded={["a"]}>
             <h4>{data.textHeader}</h4>
          {data.cards.map((cards) => (
            <AccordionItem uuid={cards.uuid}>
              <AccordionItemHeading>
                <AccordionItemButton>{cards.header}</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>{cards.content}</p>
              </AccordionItemPanel>
            </AccordionItem>
          ))}
        </Accordion>
      ))}
      </div>
      </div>
      </React.Fragment>
  );
}
